<script setup lang="ts">
    const props = defineProps<{
        title?: string
        color?: 'blue' | 'dark' | 'green' | 'red' | 'purple' | 'indigo' | 'yellow' | 'green'
        small?: boolean
        block?: boolean
        disabled?: boolean
        submit?: boolean
    }>()

    const emit = defineEmits<{
        (e: 'click', i: MouseEvent): void
    }>()

    const classColor = computed(() => {
        if (props.disabled) {
            return 'cursor-not-allowed border-gray-400 bg-gray-300 text-gray-600 opacity-80 dark:bg-slate-800 dark:text-slate-200'
        }

        switch (props.color) {
            case 'blue':
                return 'border-blue-400 bg-blue-500 text-white hover:bg-blue-700 dark:border-blue-500 dark:bg-blue-700 dark:hover:bg-blue-800'

            case 'dark':
                return 'border-gray-300 bg-gray-900 text-white hover:bg-gray-800 dark:border-slate-500'

            case 'green':
                return 'border-emerald-400 bg-emerald-500 text-white hover:bg-emerald-700 dark:border-emerald-500 dark:bg-emerald-700 dark:hover:bg-emerald-800'

            case 'red':
                return 'border-red-400 bg-red-600 text-white hover:bg-red-700 dark:border-red-600 dark:bg-red-800 dark:hover:bg-red-900'

            case 'purple':
                return 'border-purple-400 bg-purple-600 text-white hover:bg-purple-700 dark:border-purple-500 dark:bg-purple-700 dark:hover:bg-purple-800'

            case 'indigo':
                return 'border-indigo-400 bg-indigo-500 text-white hover:bg-indigo-700 dark:border-indigo-500 dark:bg-indigo-700 dark:hover:bg-indigo-800'

            case 'yellow':
                return 'border-amber-700 bg-amber-700 text-white hover:bg-amber-800 dark:border-amber-600 dark:bg-amber-800 dark:hover:bg-amber-900'

            default:
                return 'border-gray-300 bg-white text-gray-700 hover:bg-gray-100 dark:border-slate-500 dark:bg-slate-600 dark:text-white dark:hover:bg-slate-700'
        }
    })
</script>

<template>
    <button
        v-tooltip="title"
        :aria-label="title"
        class="relative inline-flex select-none items-center justify-center rounded-md border font-medium shadow-sm sm:text-sm"
        :class="[classColor, { 'w-full justify-center': block }, small ? 'px-1 py-0.5' : 'px-2 py-1']"
        :type="submit ? 'submit' : 'button'"
        :disabled="disabled"
        @click="emit('click', $event)"
    >
        <slot />
    </button>
</template>
