function createComputedAsync<T>(
    url: MaybeRefOrGetter<string>,
    defaultValue: T,
    isAdmin: MaybeRefOrGetter<boolean>,
    updateSignal: MaybeRefOrGetter<number>,
    adminOnly = true,
) {
    return computedAsync<T>(
        async () => {
            toValue(updateSignal)

            if (!adminOnly || toValue(isAdmin)) {
                return window.axios.get<T>(toValue(url)).then(response => response.data)
            }

            return defaultValue
        },
        defaultValue,
        { lazy: true },
    )
}

export const useCacheStore = defineStore('cache', () => {
    const authStore = useAuthStore()

    const updateSignal = ref(0)

    // admin
    const tiposComprobantes = createComputedAsync<Pick<App.Models.AfipTipoComprobante, 'id' | 'tipo' | 'tipo_corto' | 'letra'>[]>(
        '/api/core/tipos-comprobantes',
        [],
        authStore.admin,
        updateSignal,
    )

    const tiposResponsables = createComputedAsync<{
        tiposResponsables: Pick<App.Models.TipoResponsable, 'id' | 'tipo_responsable'>[]
        tiposProveedores: Pick<App.Models.TipoProveedor, 'id' | 'tipo_proveedor'>[]
    }>(
        '/api/core/tipos-responsables',
        {
            tiposResponsables: [],
            tiposProveedores: [],
        },
        authStore.admin,
        updateSignal,
    )

    const alicuotas = createComputedAsync<Pick<App.Models.Alicuota, 'id' | 'descripcion' | 'coeficiente'>[]>(
        '/api/core/alicuotas',
        [],
        authStore.admin,
        updateSignal,
    )

    const comprasImpuestosData = createComputedAsync<App.Models.CompraImpuestoData[]>('/api/core/compras-impuestos-data', [], authStore.admin, updateSignal)

    const licenciasTypes = createComputedAsync<Pick<App.Models.LicenciaType, 'id' | 'type' | 'color'>[]>(
        '/api/core/licencias-types',
        [],
        authStore.admin,
        updateSignal,
    )

    const chequesStates = createComputedAsync<Pick<App.Models.ChequeState, 'id' | 'state'>[]>('/api/core/cheques-state', [], authStore.admin, updateSignal)

    const mecanismosPagos = createComputedAsync<Pick<App.Models.MecanismoPago, 'id' | 'mecanismo'>[]>(
        '/api/core/mecanismos-pagos',
        [],
        authStore.admin,
        updateSignal,
    )

    const categoriasProductos = createComputedAsync<Pick<App.Models.CategoriaProducto, 'id' | 'codigo_categoria' | 'categoria'>[]>(
        '/api/core/categorias-productos',
        [],
        authStore.admin,
        updateSignal,
    )

    const marcas = createComputedAsync<Pick<App.Models.Marca, 'id' | 'codigo_marca' | 'marca'>[]>('/api/core/marcas', [], authStore.admin, updateSignal)

    const afipSituacionRevista = createComputedAsync<Pick<App.Models.AfipSituacionRevista, 'id' | 'situacion'>[]>('/api/core/situaciones-revista', [], authStore.admin, updateSignal)

    function resetStore() {
        updateSignal.value++
    }

    return {
        // admin
        tiposComprobantes,
        tiposResponsables,
        alicuotas,
        comprasImpuestosData,
        licenciasTypes,
        chequesStates,
        mecanismosPagos,
        categoriasProductos,
        marcas,
        afipSituacionRevista,

        // reset
        resetStore,
    }
})

if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useCacheStore, import.meta.hot))
}
